import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth
    },
    state: {
        loading: false
    },
    mutations: {
        loading: (state, status) => state.loading = !!(status || false),
    },
    actions: {
        load: async ({ commit }) => {
            return Promise.resolve().then(() => commit('loading', true))
        }
    }
})