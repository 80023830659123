import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'history', // hash
    base: process.env.BASE_URL,
    routes: [{
        path: '/login',
        name: 'login',
        component: Login
    },{
        path: '/:folderId?',
        name: 'folder.index',
        component: () => import( /* webpackChunkName: "user.chunk" */ './views/Folder.vue'),
        meta: {
            requiresAuth: true,
            newFile: true,
            newFolder: true,
            logout: true
        }
    },{
        path: '/:folderId/create',
        name: 'file.create',
        component: () => import( /* webpackChunkName: "user.chunk" */ './views/FileCreate.vue'),
        meta: {
            requiresAuth: true,
            back: true,
            save: true,
            input: true,
            logout: true
        }
    },{
        path: '/:folderId/:fileId',
        name: 'file.view',
        component: () => import( /* webpackChunkName: "user.chunk" */ './views/FileView.vue'),
        meta: {
            requiresAuth: true,
            back: true,
            edit: true,
            delete: true,
            logout: true
        }
    },{
        path: '/:folderId/:fileId/edit',
        name: 'file.edit',
        component: () => import( /* webpackChunkName: "user.chunk" */ './views/FileEdit.vue'),
        meta: {
            requiresAuth: true,
            back: true,
            save: true,
            delete: true,
            input: true,
            logout: true
        }
    },{
        path: '/**',
        redirect: { name: 'folder.index' }
    }]
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    // No authentication needed
    if (!requiresAuth) {
        return next()
    }

    // Not authenticated
    if (!store.state.auth.token) {
        if (store.state.loading) {
            store.commit('loading')
        }
        store.commit('auth/clear')
        return next({ name: 'login' })
    }

    // Is authenticated
    const isFirstLoad = !Object.keys(store.state.auth.user).length
    if (isFirstLoad) { // If user has not been fetched
        try {
            await store.dispatch('auth/user')
        } catch {
            store.commit('auth/clear')
            next({ name: 'login' })
        }
    }

    // Renew User token
    try {
        store.commit('loading', true)
        await store.dispatch('auth/renew')
        store.commit('loading')
    } catch {
        store.commit('auth/clear')
        next({ name: 'login' })
    } finally {
        store.commit('loading')
    }

    next()
})

// eslint-disable-next-line
router.afterEach((to, from) => {
    if (store.state.loading) {
        store.commit('loading')
    }
})

export default router
