<template>
    <div id="app">
        <div class="loader"></div>
        <topbar/>
        <!-- <transition name="slide-down" mode="out-in"> -->
            <router-view v-bind:key="$route.fullPath"/>
        <!-- </transition> -->
        <transition name="slide-left" mode="in-out">
            <div class="alert" v-bind:class="'alert--' + alert.status" v-if="alert">
                <div class="alert__content">{{ alert.message }}</div>
                <div class="alert__actions">
                    <button class="button button--small button--icon button--flat" v-on:click="alertClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Topbar from "./components/layout/Topbar.vue"

export default {
    name: "app",
    components: {
        Topbar
    },
    data() {
        return {
            alert: null
        }
    },
    methods: {
        alertClose() {
            this.alert = null
        },
        closeAllDropdowns() {
            document.querySelectorAll(".menu--open").forEach(elm => {
                elm.__vue__.close()
            })
        }
    },
    mounted() {
        const darkMode = localStorage.getItem('dark_mode') === "true" || false
        if (darkMode) {
            document.documentElement.classList.add('dark-mode')
        } else {
            document.documentElement.classList.remove('dark-mode')
        }
        this.$root.$on('alert', alert => {
            this.alert = alert
            setTimeout(() => {
                this.alert = null
            }, 3000)
        })
        this.$root.$on('topbar-toggle-darkmode', () => {
            const darkMode = localStorage.getItem('dark_mode') === "true" || false
            localStorage.setItem('dark_mode', !darkMode)
            if (!darkMode) {
                document.documentElement.classList.add('dark-mode')
            } else {
                document.documentElement.classList.remove('dark-mode')
            }

        })
        this.$root.$on('topbar-logout', () => {
            this.$store.dispatch('load')
            .then(() => this.$store.dispatch('auth/logout'))
            .then(() => this.$router.push({ name: 'login' }))
            .catch(() => this.$router.push({ name: 'login' }))
        })
    },
    beforeDestroy() {
        this.$root.$off('alert')
    }
}
</script>


<style lang="scss">
@import "scss/app"
</style>
