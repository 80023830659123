import DeviceUUID from 'device-uuid'
const uuid = (new DeviceUUID.DeviceUUID()).get()

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('auth') || '',
        user: {},
    },
    getters: {
        loggedIn(state) {
            return !!state.token
        },
        axios_options(state) {
            return {
                headers: {
                    'authorization': `Bearer ${state.token}`,
                    'device-uuid': uuid
                }
            }
        }
    },
    mutations: {
        token(state, token) {
            state.token = token
            if (!token.length) {
                localStorage.removeItem('auth')
            } else {
                localStorage.setItem('auth', token)
            }
        },
        user(state, user) {
            state.user = user
        },
        clear(state) {
            state.token = ''
            state.user = {}
            localStorage.removeItem('auth')
        }
    },
    actions: {
        get({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return axios.get(url, Object.assign(getters['axios_options'], options))
        },
        post({ getters }, { url, data, options }) {
            url = url || ''
            data = data || {}
            options = options || {}
            return axios.post(url, data, Object.assign(getters['axios_options'], options))
        },
        delete({ getters }, { url, options }) {
            url = url || ''
            options = options || {}
            return axios.delete(url, Object.assign(getters['axios_options'], options))
        },
        async user({ commit, getters }) {
            try {
                const res = await axios.get('/v1/auth/user', getters['axios_options'])

                commit('user', res.data.data)

                return Promise.resolve(res)
            } catch (err) {
                commit('clear')
                throw err
            }
        },
        async login({ commit }, data) {
            try {
                const res = await axios.post('/v1/auth/login', data, { headers: { 'device-uuid': uuid }})
                commit('token', res.data.token)
            } catch (err) {
                console.error(err)
                throw err
            }
        },
        async renew({ state, getters }) {
            if (!state.token) {
                throw new Error('Token not found')
            }
            try {
                await axios.get('/v1/auth/renew', getters['axios_options'])
            } catch (err) {
                throw err
            }
        },
        async reissue({ commit, getters }) {
            try {
                const res = await axios.get('/v1/auth/reissue', getters['axios_options'])
                commit('token', res.data.token)
            } catch (err) {
                throw err
            }
        },
        async logout({ commit, getters }) {
            try {
                await axios.get('/v1/auth/logout', getters['axios_options'])
                commit('clear')
            } catch (err) {
                throw err
            }
        }
    }
}
