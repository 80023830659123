import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import validator from './plugins/validator'

window.axios = axios.create({
    baseURL: 'https://manual-api.tsndr.de'
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    validator,
    render: h => h(App)
}).$mount('#app')
