<template>
    <header class="header header--primary">
        <div class="header__actions">
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.back"
                v-on:click="clickBack"
            >
                <i class="material-icons">arrow_back</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.edit"
                v-on:click="clickEdit"
            >
                <i class="material-icons">edit</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.save"
                v-on:click="clickSave"
            >
                <i class="material-icons">save</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.delete"
                v-on:click="clickDelete"
            >
                <i class="material-icons">delete</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.newFile"
                v-on:click="clickNewFile"
            >
                <i class="material-icons">description</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.newFolder"
                v-on:click="clickNewFolder"
            >
                <i class="material-icons">create_new_folder</i>
            </button>
        </div>
        <div class="header__logo" v-if="!input">
            <router-link to="/">Manual</router-link>
        </div>
        <div class="header__input" v-if="input">
            <input type="text" v-model="name" placeholder="Name" v-on:keyup.enter="clickSave">
        </div>
        <div class="header__options">
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-on:click="toggleDarkmode"
            >
                <i class="material-icons">{{ darkModeIcon }}</i>
            </button>
            <button
                type="button"
                class="button button--primary button--flat button--icon"
                v-if="icon.logout"
                v-on:click="clickLogout"
            >
                <i class="material-icons">exit_to_app</i>
            </button>
        </div>
    </header>
</template>

<script>
import _ from "lodash"

export default {
    data() {
        return {
            icon: {
                back: false,
                edit: false,
                save: false,
                delete: false,
                logout: false,
                newFile: false,
                newFolder: false
            },
            input: false,
            name: "",
            darkModeIcon: "brightness_low"
        }
    },
    methods: {
        updateButtons(route) {
            const meta = route.meta || {}
            for (const k in this.icon) {
                this.icon[k] = meta[k] || false
            }
            this.input = meta.input || false

            // Showing back arrow if route folder.index and param folderId
            if (route.name === "folder.index" && route.params.folderId > 0) {
                this.icon.back = true
            }
        },
        clickBack() {
            this.$root.$emit("topbar-back")
        },
        clickEdit() {
            this.$root.$emit("topbar-edit")
        },
        clickSave() {
            this.$root.$emit("topbar-save")
        },
        clickDelete() {
            this.$root.$emit("topbar-delete")
        },
        clickNewFile() {
            this.$root.$emit("topbar-new-file")
        },
        clickNewFolder() {
            this.$root.$emit("topbar-new-folder")
        },
        toggleDarkmode() {
            const darkMode = localStorage.getItem('dark_mode') === "true" || false
            this.darkModeIcon = !darkMode ? "brightness_7" : "brightness_4"
            this.$root.$emit("topbar-toggle-darkmode")
        },
        clickLogout() {
            this.$root.$emit("topbar-logout")
        }
    },
    watch: {
        $route(to) {
            this.updateButtons(to)
        },
        name: _.debounce(function() {
            this.$root.$emit('topbar-name-input', this.name)
        }, 300)
    },
    created() {
        this.updateButtons(this.$route)
    },
    mounted() {
        const darkMode = localStorage.getItem('dark_mode') === "true"
        this.darkModeIcon = darkMode ? "brightness_7" : "brightness_4"
        this.$root.$on('file-edit-load', file => {
            this.name = file.name
        })
        this.$root.$on('file-create-clear-name', () => {
            this.name = ''
        })
        this.$root.$on('file-edit-clear-name', () => {
            this.name = ''
        })
    },
    beforeDestroy() {
        this.$root.$off('file-edit-load')
        this.$root.$off('file-create-clear-name')
        this.$root.$off('file-edit-clear-name')
    }
}
</script>

